.contact-form-wrap {
  h1,
  h4,
  h5,
  p,
  a {
    color: #ffffff !important;
  }

  .form-control {
    background: rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 10px;
    border: 1px solid transparent;
    height: 50px;
    border-radius: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
  .form-control:focus {
    background: rgba(255, 255, 255, 0.4);
  }
  textarea.form-control {
    height: 170px;
    resize: none;
  }

  .invalid-field {
    border: 1px solid rgba(255, 27, 27, 0.5);
  }
}
