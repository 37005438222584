.actiond-module-heading {
  .actiond-module-icon {
    width: 70px;
    min-width: 70px;
    height: 70px;
    background-image: url("https://actiond.azureedge.net/public-assets/website/circle-bg.png");
    background-size: 70px 70px;
    text-align: center;

    i {
      font-size: 35px;
      line-height: 70px;
    }

    .fa-exclamation-triangle {
      line-height: 65px;
    }
    .fa-clipboard-check {
      line-height: 68px;
    }
  }

  h2 {
    font-size: 1.8em;
    line-height: 1em;
    margin: 0;
  }
  h3 {
    font-size: 1.3em;
    line-height: 1em;
    margin: 6px 0 0 0;
  }
}
