@import "./assets/styles/variables.scss";
@import "./assets/styles/buttons.scss";
@import "./assets/styles/colors.scss";
@import "./assets/styles/gradients.scss";
@import "./assets/styles/icons.scss";

/* Start Main Rules */
html {
  scroll-behavior: smooth;
}

html,
body {
  background-color: #ffffff;
  min-height: 100vh;
  position: relative;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

main {
  padding-top: 68px;
}

.p100 {
  padding: 100px 0;
}

.h-100 {
  min-height: calc(100vh - 68px);
}

.upper {
  text-transform: uppercase;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fs-larger {
  font-size: 1.15em;
  line-height: 1.85;
}

p {
  font-size: 16px;
  margin: 15px 0;
  line-height: 2;
  color: #333;
}

.text-list {
  padding-left: 0;
  li {
    list-style-type: none;
    line-height: 1.75;
  }
}

.dot-list {
  li {
    line-height: 1.75;
  }
}

.title {
  margin-bottom: 30px;

  i {
    font-size: 35px;
    margin-bottom: 15px;
  }
  h2 {
    position: relative;
    font-weight: 700;
    color: #000;
  }
  p {
    margin: 15px auto;
  }
}

.link {
  color: $solvableBlue;
}

.bg-text {
  font-size: 2em;
  color: #333;
  opacity: 0.04;
  position: absolute;
  top: -20px;
  text-align: inherit;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.title .box {
  margin: 25px 0;
  position: relative;
  z-index: 2;
}

a:hover,
a:focus {
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: #fff !important;
}
::-moz-placeholder {
  color: #fff !important;
}
:-ms-input-placeholder {
  color: #fff !important;
}
:-moz-placeholder {
  color: #fff !important;
}

img {
  max-width: 100%;
}

.profile-image {
  border-radius: 50%;
}

.linkedin {
  max-height: 20px;
}

.line {
  width: 80%;
  height: 1px;
  margin: 15px auto;
  background-color: $solvableBlue;
  opacity: 0.5;
}

.testimonial,
.testimonial-name {
  color: #555 !important;
  font-style: italic;
}

/* End Main Rules */

/* Start Pretty Scrollbar */
body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-thumb {
  background: $solvablePurple;
}
body::-webkit-scrollbar-track {
  background: #f7f7f7;
}
/* End Pretty Scrollbar */
