@import "./variables.scss";

.container-bg-grey {
  background-color: #f4f4f4;
}

.container-bg-grey-shadow {
  background-color: #f4f4f4;
  box-shadow: inset 0px 6px 8px -3px rgba(0, 0, 0, 0.1);
}

.gry {
  background-color: #f5f7fb;
}

.darkgry {
  background-color: #f0f2f5;
}

.fixed-width-fluid {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.content-section {
  h1,
  h2 {
    font-family: $headingFontFamily;
    color: #000000;
    font-weight: 600;
  }

  h3,
  h4,
  h5,
  h6 {
    font-family: $headingFontFamily;
    color: #000000;
  }

  p {
    color: $paragraphColor;
  }

  li {
    color: $paragraphColor;
  }

  ul.two-col {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    @media only screen and (max-width: 500px) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }
  }

  .image-title {
    font-size: 0.85em;
  }
}

.screenshot {
  border: 1px solid #939598;
}
