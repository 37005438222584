.learn {
  padding: 12px 25px;
  border-radius: 23px;
  text-transform: uppercase;
  color: #fff !important;

  i {
    margin-right: 0px;
    font-size: 14px !important;
  }
}
