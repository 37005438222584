@import "./variables.scss";

.gradient-opacity {
  background: -webkit-linear-gradient(right, $gradientGreen90, $gradientBlue90);
  background: -o-linear-gradient(right, $gradientGreen90, $gradientBlue90);
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from($gradientGreen90),
    to($gradientBlue90)
  );
  background: linear-gradient(to right, $gradientGreen90, $gradientBlue90);
}

.gradient {
  background: -webkit-linear-gradient(
    right,
    $gradientBlue,
    $gradientGreen
  ) !important;
  background: -o-linear-gradient(
    right,
    $gradientBlue,
    $gradientGreen
  ) !important;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from($gradientBlue),
    to($gradientGreen)
  ) !important;
  background: linear-gradient(
    to left,
    $gradientBlue,
    $gradientGreen
  ) !important;
}

.grey-gradient {
  background: -webkit-linear-gradient(
    right,
    $gradientDarkGrey,
    $gradientLightGrey
  ) !important;
  background: -o-linear-gradient(
    right,
    $gradientDarkGrey,
    $gradientLightGrey
  ) !important;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from($gradientDarkGrey),
    to($gradientLightGrey)
  ) !important;
  background: linear-gradient(
    to left,
    $gradientDarkGrey,
    $gradientLightGrey
  ) !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
