.hero {
  background-image: url("https://solvable.azureedge.net/website-assets/hero.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;

  .box {
    h1 {
      text-transform: capitalize;
      font-weight: 500;
      min-height: 40px;
      color: #fff;
    }
    h3 {
      text-transform: capitalize;
      font-weight: 600;
      min-height: 35px;
      color: #fff;
    }
    p {
      color: #f8f9fb;
    }
    .learn,
    .touch {
      padding: 12px 25px;
      border: 2px solid #fff;
      border-radius: 24px;
      text-transform: uppercase;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      min-width: 175px;
      color: #fff;
    }
    .learn i,
    .touch i {
      margin-right: 10px;
    }
    .learn:hover,
    .touch:hover {
      background-color: #fff;
    }
    .btn {
      margin: 0 20px 20px 0;
    }
    img {
      display: block;
      margin: auto;
      max-height: 500px;
    }
  }
}
