footer {
  background: #fff;
  -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  width: 100%;

  .container {
    p {
      text-transform: capitalize;
      margin: 0;
      padding: 15px 0;

      a {
        color: #333;
      }
    }
  }
}
