$solvablePurple: #6d4da0;
$solvableBlue: #1377a2;
$solvableGreen: #179ea3;
$headingFontFamily: "Poppins", sans-serif;
$paragraphColor: #000000;

$gradientBlue: #2e8d9f;
$gradientGreen: #3fcb93;

$gradientBlue90: rgba(46, 141, 159, 0.9);
$gradientGreen90: rgba(63, 203, 147, 0.9);

$gradientLightGrey: rgba(16, 16, 16, 0.8);
$gradientDarkGrey: rgba(16, 16, 16, 0.7);
