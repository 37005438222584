.what-col {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  justify-content: space-between;

  i {
    font-size: 35px;
  }

  h3 {
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    font-size: 24px;
    margin: 20px 0 10px 0;
  }
}
