.sticky-nav {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
  box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
}

.navbar-default {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  // padding: 20px;
  background: none;
  border: none;
  /* min-height: 78px !important; */
  /* height: 78px; */

  .collapse {
    justify-content: flex-end;
  }

  .navbar-nav > li > a,
  .navbar-nav > li > a:focus {
    color: #fff;
  }
  .navbar-brand {
    padding: 0;

    img {
      display: inline-block;
      max-height: 60px;
      padding-bottom: 8px;
    }
  }
  .navbar-nav {
    padding: 10px 20px;
  }
  .navbar-nav > li {
    text-transform: uppercase;
    margin-right: 2px;
  }
  .navbar-nav > li:last-child {
    margin-right: 0;
  }
  .navbar-nav > li > a,
  .dropdown-menu a {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .navbar-nav > li > a {
    padding: 4px 6px;
  }
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > li > a:hover,
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .open > a:hover {
    background-color: #fff;
    border-radius: 3px;
  }
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > li > a:hover {
    color: #000 !important;
  }
  .navbar-toggler {
    border: none;
    color: #ffffff;
  }
  .navbar-toggler:hover,
  .navbar-toggler:focus {
    background-color: transparent;
  }

  .navbar-collapse,
  .navbar-form {
    border-color: rgba(231, 231, 231, 0.15);
  }
  .navbar-brand img {
    padding: 0 !important;
    max-height: 50px;
  }
}

.dropdown-menu {
  border: none;
  top: 150%;
}
.dropdown-menu a {
  padding: 5px 20px;
  color: #fff !important;
  background: none !important;
  outline: none !important;
}

@media (max-width: 767px) {
  .navbar-default {
    background-color: #000;
  }
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > li > a:hover {
    color: #6c4e9f !important;
  }
  .navbar-nav {
    padding: 10px !important;
  }
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > li > a:hover {
    background: none;
  }
}

@media (max-width: 991px) {
  .navbar-default {
    .navbar-nav {
      padding: 10px;
    }
  }
}
