.slvicon {
  width: 82px;
  height: 74px;
  background-image: url("https://solvable.azureedge.net/website-assets/why-use-solvable-sprites.png");
  margin: 0 auto;
}
.slvicon-why {
  background-position: 0 0;
}
.slvicon-speak {
  background-position: 0 -74px;
}
.slvicon-responsive {
  background-position: 0 -148px;
}
.slvicon-think {
  background-position: 0 -222px;
}
.slvicon-risk {
  background-position: 0 -296px;
}
.slvicon-seen {
  background-position: 0 -370px;
}
.slvicon-more {
  background-position: 0 -444px;
}

.slvicon-software {
  width: 75px;
  background-image: url("https://solvable.azureedge.net/website-assets/software-scope-design-and-build-icon.png");
  background-size: 75px 74px;
}

.slvicon-integration {
  width: 94px;
  background-image: url("https://solvable.azureedge.net/website-assets/systems-integration-and-middleware-icon.png");
  background-size: 94px 74px;
}

.slvicon-productivity {
  width: 85px;
  background-image: url("https://solvable.azureedge.net/website-assets/productivity-and-automation-tools-icon.png");
  background-size: 85px 74px;
}
