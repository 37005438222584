.our-highlights {
  .highlight {
    text-align: center;
    // margin: 15px 0;

    .title {
      display: block;
      font-weight: 600;
      margin: 0 0 8px 0;
    }
    .details {
      display: block;
    }
  }

  .row:nth-child(even) .highlight:nth-of-type(2) {
    background-color: #f5f7fb;
  }
  .row:nth-child(odd) .highlight:nth-of-type(1) {
    background-color: #f5f7fb;
  }

  @media screen and (max-width: 575px) {
    .row:nth-child(even) .highlight:nth-of-type(1) {
      background-color: #f5f7fb;
    }
    .row:nth-child(even) .highlight:nth-of-type(2) {
      background-color: #ffffff;
    }
  }
}
