.why {
  .box {
    i {
      font-size: 35px;
    }

    h3 {
      text-transform: uppercase;
      font-weight: bold;
      color: #000;
      font-size: 24px;
      margin: 20px 0 10px 0;
    }
  }
}
