@import "./variables.scss";

.color-1 {
  color: $solvableBlue !important;
}
.color-2 {
  color: $solvableGreen !important;
}
.color-3 {
  color: $solvablePurple !important;
}
.color-1-hv:hover {
  color: $solvableBlue !important;
}
.color-2-hv:hover {
  color: $solvableGreen !important;
}
.color-3-hv:hover {
  color: $solvablePurple !important;
}
.color-1-bg {
  background-color: $solvableBlue;
}
.color-2-bg {
  background-color: $solvableGreen;
}
.color-3-bg {
  background-color: $solvablePurple;
}
.color-1-bg-hv:hover {
  background-color: $solvableBlue !important;
}
.color-2-bg-hv:hover {
  background-color: $solvableGreen !important;
}
.color-3-bg-hv:hover {
  background-color: $solvablePurple !important;
}
.color-staffd-blue {
  color: #30c2f2 !important;
}
.color-staffd-green {
  color: #2fbb96 !important;
}
