.contact-details {
  h4 {
    font-size: 1.15em;
    margin-bottom: 8px;
    font-family: 500;
  }
  p {
    font-size: 0.9em;
    margin: 0 0 25px 0;
  }

  .contact-icons {
    i {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 50%;
      color: #fff;
    }
    i.fa-linkedin {
      background-color: #0077b5;
    }
  }
}
